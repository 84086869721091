import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
    name: 'login',
    meta: { title: '登录' }
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true,
    name: 'register',
    meta: { title: '注册' }
  },
  {
    path: '/passwordReset',
    component: () => import('@/views/passwordReset'),
    hidden: true,
    name: 'password.reset',
    meta: { title: '重置密码' }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/map',
    component: () => import('@/views/map'),
    hidden: true,
    name: 'map',
    meta: { title: '地图位置' }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: false,
    name: 'Index',
    meta: { title: '首页', icon: 'dashboard' },
    children: [
      {
        path: 'home',
        component: () => import('@/views/index'),
        name: 'index',
        meta: { title: '平台首页' },
        hidden: true
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
const router = createRouter({
  routes: constantRoutes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  }
})

export const resetRouter = () => {
  const newRouter = createRouter()
  router.resolve = newRouter.resolve
}

export default router
