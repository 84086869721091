<template>
  <el-menu
    :collapse="!state.app.openMenu"
    :default-active="activeMenu"
    :unique-opened="true"
    :router="true"
  >
    <menu-item v-for="(item, index) in routers" :key="index" :item="item" />
  </el-menu>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import menuItem from './MenuItem.vue'

const { getters, state } = useStore()
const routers = reactive(getters.routers)

const route = useRoute()
const router = useRouter()
const activeMenu = ref(route.name || '')

watch(() => router.currentRoute.value.path, () => {
  activeMenu.value = router.currentRoute.value.name
}, { immediate: true, deep: true })
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu-item-group__title {
  padding: 0 !important;
}
</style>
