
const title = process.env.VUE_APP_NAME

const pageTitle = metaTitle => {
  if (metaTitle) {
    return `${metaTitle} - ${title}`
  }
  return `${title}`
}

export default pageTitle
