import * as model from '@/utils/apis/company/admins'
import * as Cache from '@/utils/tools/cache'
import Layout from '@/layout'
import { constantRoutes } from '@/router'
import router from '@/router'

const state = () => ({
  accessToken: Cache.get('companyAccessToken') || '',
  adminInfo: {},
  routers: constantRoutes
})

const mutations = {
  SET_ACCESS_TOKEN: (state, token) => {
    if (token) {
      Cache.set('companyAccessToken', token)
    } else {
      Cache.del('companyAccessToken')
    }
    state.accessToken = token
  },
  SET_ADMIN_INFO: (state, adminInfo) => {
    state.adminInfo = adminInfo
  },
  SET_ROUTERS: (state, routers) => {
    state.routers = routers
  }
}

const actions = {
  login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      model.login(loginData).then(response => {
        commit('SET_ACCESS_TOKEN', response.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  passwordReset({ commit }, data) {
    return new Promise((resolve, reject) => {
      model.passwordReset(data).then(res => {
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_ADMIN_INFO', '')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  logout({ commit }) {
    commit('SET_ACCESS_TOKEN', '')
    commit('SET_ADMIN_INFO', '')
  },
  info({ commit }) {
    return new Promise((resolve, reject) => {
      model.info().then(res => {
        commit('SET_ADMIN_INFO', res)
        const systemRoutes = [...constantRoutes]
        generaMenu(systemRoutes, res.menus)
        systemRoutes.push({
          path: '/:pathMatch(.*)*',
          hidden: true,
          component: () => import('@/views/error-page/404')
        })
        commit('SET_ROUTERS', systemRoutes)
        resolve(systemRoutes)
      }).catch(() => {
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_ADMIN_INFO', '')
        router.push(`/login`)
      })
    })
  }
}

/**
 * 后台查询的菜单数据拼装成路由格式的数据
 * @param routers
 */
const generaMenu = (constantRouters, routers) => {
  routers.forEach(item => {
    const menu = {
      path: item.path,
      component: item.component === 'Layout' ? Layout : () => import(`@/views${item.component}`),
      children: [],
      name: item.name,
      hidden: item.is_show === 0,
      meta: { title: item.title, icon: item.icon, id: item.id }
    }
    if (item.redirect) {
      menu.redirect = item.redirect
    }
    if (item.children) {
      generaMenu(menu.children, item.children)
    }
    constantRouters.push(menu)
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
