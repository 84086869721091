import request from '@/utils/apis/request'

export const loginRules = {
  phone: [{ type: 'string', required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号！', trigger: 'change' }]
}

export const resetPasswordRules = {
  phone: [{ type: 'string', required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号！', trigger: 'change' }],
  code: [{ type: 'string', required: true, pattern: /^\d{6}$/, message: '请输入6位数短信验证码！', trigger: 'change' }],
  password: [{ required: true, message: '请输入新密码！', trigger: 'change' }]
}

export const rules = {
  username: [{ required: true, message: '请输入管理员姓名！', trigger: 'change' }],
  phone: [{ type: 'string', required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号！', trigger: 'change' }],
  type: [
    { required: true, message: '请选择管理员类型！', trigger: 'change' },
    { type: 'number', min: 1, message: '请选择管理员类型！', trigger: 'blur' }
  ]
}

export const login = data => {
  return request({
    method: 'post',
    data: data,
    url: '/backendApi/login'
  })
}

export const passwordReset = data => {
  return request({
    method: 'post',
    data: data,
    url: '/backendApi/passwordReset'
  })
}

export const info = () => {
  return request({
    method: 'post',
    url: '/backendApi/admins/loginInfo'
  })
}

export const menuList = () => {
  return request({
    method: 'post',
    url: '/backendApi/menus/list'
  })
}

export const list = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/list',
    data
  })
}

export const add = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/add',
    data
  })
}

export const edit = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/edit',
    data
  })
}

export const setting = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/setting',
    data: data
  })
}

export const del = id => {
  return request({
    method: 'post',
    url: `/backendApi/admins/del/${id}`
  })
}

export const changeStatus = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/editStatus',
    data: data
  })
}
