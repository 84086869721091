import { ElMessage } from 'element-plus'

const success = (msg, callback = null, time = 2000) => {
  ElMessage({
    message: msg,
    duration: time,
    type: 'success'
  })
  if (typeof callback === 'function') {
    let timer = null
    timer = setTimeout(() => {
      callback()
      clearTimeout(timer)
    }, 1000)
  }
}

const error = (msg, callback = null, time = 2000) => {
  ElMessage({
    message: msg,
    duration: time,
    type: 'error'
  })
  if (typeof callback === 'function') {
    let timer = null
    timer = setTimeout(() => {
      callback()
      clearTimeout(timer)
    }, 100)
  }
}

export default {
  success,
  error
}
