<template>
  <div class="content-menu">
    <div class="slide-menu" @click="menuTagger()">
      <SvgIcon :class="{ active: state.app.openMenu }" name="openmenu" color="#000000" :size="16" />
    </div>
    <div v-if="state.app.device === 'desktop'" class="top-breadcrumb">
      <BreadCrumb />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import BreadCrumb from './BreadCrumb.vue'

const { dispatch, state } = useStore()
const menuTagger = () => {
  dispatch('app/menuTagger')
}

</script>
