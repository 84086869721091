<template>
  <div class="container" :class="{ closeMenu: !state.app.openMenu }">
    <div class="top-header">
      <div class="log-icon">
        <img :src="requireImg('logo-icon-back.png')" alt="">
        <span>企竞助力管理后台</span>
      </div>
      <div class="search-tips">
        <!-- <marquee>
          <div class="font-scroll">
            <img :src="requireImg('laba.png')">
            <span style="color: #fff">这里是文字</span>
          </div>
        </marquee> -->
      </div>
      <div class="header-right">
        <div class="icons">
          <div class="icon-item" @click="toScreenFull">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="在线客服"
              placement="top-start"
            >
              <el-icon :size="18" color="#ffffff"><Service /></el-icon>
            </el-tooltip>
          </div>
          <div class="icon-item" @click="toScreenFull">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="全屏展示"
              placement="top-start"
            >
              <el-icon :size="18" color="#ffffff"><FullScreen /></el-icon>
            </el-tooltip>
          </div>
          <div class="icon-item message">
            <!-- <span class="number">5</span> -->
            <el-tooltip
              class="box-item"
              effect="dark"
              content="系统消息"
              placement="top-start"
            >
              <el-icon :size="18" color="#ffffff"><BellFilled /></el-icon>
            </el-tooltip>
          </div>
        </div>
        <el-dropdown trigger="click">
          <div class="company-user el-dropdown-link">
            <el-avatar shape="square" :size="34" :src="state.user.adminInfo.company.logo || requireImg('d-avatar.png')" />
            <div class="icon">
              <el-icon :size="20" color="#66a0e1"><CaretBottom /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Tools" @click="passwordReset">修改密码</el-dropdown-item>
              <el-dropdown-item divided :icon="SwitchButton" @click="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="layout-content">
      <div class="menu-container">
        <div class="menu-header">
          <div class="left">
            <el-avatar shape="square" :size="36" :src="state.user.adminInfo.company.logo || requireImg('d-avatar.png')" />
          </div>
          <div class="right">
            <div class="company-name">{{ state.user.adminInfo.company.company_name }}</div>
            <div class="company-auth">
              <img :src="requireImg('auth-c.png')">
              <span>企业认证</span>
            </div>
          </div>
        </div>
        <el-scrollbar height="100%">
          <div class="menu-list">
            <Menu />
          </div>
        </el-scrollbar>
      </div>
      <div class="main-container">
        <TopHeader />
        <div class="main-content">
          <router-view v-slot="{ Component, route}">
            <component :is="Component" :key="route.fullPath" />
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import Menu from './components/Menu.vue'
import TopHeader from './components/TopHeader.vue'
import { useStore } from 'vuex'
import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { CaretBottom, BellFilled, FullScreen, Service, Tools, SwitchButton } from '@element-plus/icons-vue'
import ScreenFull from 'screenfull'
import msg from '@/utils/tools/msg'

const requireImg = path => {
  return require(`@/assets/imgs/${path}`)
}

const toScreenFull = () => {
  ScreenFull.toggle()
}

const { state, dispatch } = useStore()
console.log(state.user.adminInfo.company.company_name)
const minWidth = 875
const setDevice = () => {
  const clientWidth = document.body.clientWidth
  const isMobile = clientWidth <= minWidth
  dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')
  // 移动设备关闭菜单
  if (isMobile && state.app.openMenu) dispatch('app/menuTagger')
  // 桌面设备展开
  if (!isMobile && !state.app.openMenu) dispatch('app/menuTagger')
}

setDevice()

onMounted(() => {
  window.onresize = () => {
    setDevice()
  }
})

const router = useRouter()
const routeIn = useRoute()
const logout = () => {
  dispatch('user/logout')
  msg.success('退出登录成功！', () => {
    router.push(`/login?redirect=${routeIn.path}`)
  })
}

const passwordReset = () => {
  router.push('/passwordReset')
}
</script>

<style lang="scss">
@import '../assets/styles/layout.scss';
</style>
