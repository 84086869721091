<template>
  <div v-if="!menu.hidden">
    <el-sub-menu v-if="hasChildren" :index="menu.name">
      <template #title>
        <i v-if="menu.meta.icon" class="el-icon">
          <SvgIcon class="menu-icon" :name="menu.meta.icon" :size="14" />
        </i>
        <span class="title-font">{{ menu.meta.title }}</span>
      </template>
      <el-menu-item-group>
        <menu-item v-for="(child, index) in menu.children" :key="index" :item="child" />
      </el-menu-item-group>
    </el-sub-menu>
    <el-menu-item v-else :index="menu.name" @click="menuJump(item)">
      <i v-if="menu.meta.icon" class="el-icon">
        <SvgIcon class="menu-icon" :name="menu.meta.icon" :size="14" />
      </i>
      <span class="title-font">{{ menu.meta.title }}</span>
    </el-menu-item>
  </div>
</template>

<script setup>
import { defineProps, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { isExternal } from '@/utils/validate'

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
    required: true
  }
})

const menu = reactive(props.item)
const checkChilren = () => {
  if (!menu.children) return false
  // 获取没有隐藏的菜单数量
  let number = 0
  menu.children.forEach(item => {
    if (!item.hidden) {
      number += 1
    }
  })
  return number > 0
}

const hasChildren = ref(checkChilren())
const router = useRouter()

const menuJump = () => {
  const routePath = menu.redirect ? menu.redirect : menu.path
  if (isExternal(routePath)) {
    window.open(routePath, '_blank')
  } else {
    router.back = false
    router.push(`${routePath}`)
  }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu-item-group__title {
  padding: 0 !important;
}
</style>
