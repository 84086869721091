import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import './assets/styles/root.css'
import '@/assets/icons'
import SvgIcon from '@/components/SvgIcon'
import DelButton from '@/components/Roles/DelButton'
import store from './store'
// 路由守卫
import './route.guard'
// 全局方法
import msg from '@/utils/tools/msg'
import helper from '@/utils/helper'

const app = createApp(App)

app.provide('$msg', msg)
app.provide('$helper', helper)

app.use(router)
app.use(ElementPlus, {
  locale: zhCn
})
app.use(store)
app.component('SvgIcon', SvgIcon)
app.component('DelButton', DelButton)

app.mount('#app')
