<template>
  <el-popconfirm title="您确定要删除该选项吗?" @confirm="confirm()">
    <template #reference>
      <el-button :loading="loading" type="danger" size="small">删除</el-button>
    </template>
  </el-popconfirm>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
    required: false
  }
})

const emit = defineEmits(['confirm'])
const confirm = () => {
  emit('confirm')
}
</script>
