import axios from 'axios'
import msg from '@/utils/tools/msg'
import store from '@/store'

const service = axios.create({
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.accessToken) config.headers['token'] = store.getters.accessToken
    return config
  },
  error => {
    msg.error('系统错误！', () => {}, 2000)
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      if (res.code === 401) {
        msg.error(res.message, () => {
          store.dispatch('user/logout')
        }, 2000)
      } else {
        msg.error(res.message, () => {}, 2000)
        return Promise.reject(new Error(res.message || 'Error'))
      }
    } else {
      return res.data
    }
  },
  error => {
    msg.error(error.message, () => {}, 2000)
    return Promise.reject(error)
  }
)

export default service
