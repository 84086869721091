// 比较差异的方法
const editData = (infoData, formData, key = 'id') => {
  const diffData = {}
  for (var k in formData) {
    if (formData[k] !== infoData[k]) {
      diffData[k] = formData[k]
    }
  }

  if (!emptyObject(diffData)) diffData[key] = infoData[key]

  return diffData
}

const emptyObject = obj => Object.keys(obj).length === 0

const empty = value => {
  return value === null || value === 'null' || value === '' || value === undefined
}

const toUrlParams = params => {
  let str = ''
  Object.keys(params).map(key => {
    if (!empty(params[key])) {
      str += key + '=' + params[key] + '&'
    }
  })
  return '?' + str.substr(0, str.length - 1)
}

const queryToParams = (query, params) => {
  var newParams = {}
  for (var i in params) {
    if (query[i] !== undefined) {
      newParams[i] = isNaN(Number(query[i])) ? query[i] : Number(query[i])
    } else {
      newParams[i] = params[i]
    }
  }
  return newParams
}

export const openLink = url => {
  window.open(url, '_blank')
}

export const getBeforeDate = number => {
  const num = number
  const date = new Date()
  let year = date.getFullYear()
  let mon = date.getMonth() + 1
  let day = date.getDate()
  if (day <= num) {
    if (mon > 1) {
      mon = mon - 1
    } else {
      year = year - 1
      mon = 12
    }
  }
  date.setDate(date.getDate() - num)
  year = date.getFullYear()
  mon = date.getMonth() + 1
  day = date.getDate()
  const s = year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day)
  return s
}

export const getDay = () => {
  const date = new Date()
  var year = date.getFullYear()
  var mon = date.getMonth() + 1
  var day = date.getDate()

  const s = year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day)
  return s
}

export default {
  editData,
  emptyObject,
  toUrlParams,
  empty,
  queryToParams,
  openLink,
  getBeforeDate,
  getDay
}
