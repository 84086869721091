import * as Cache from '@/utils/tools/cache'

const state = () => ({
  openMenu: Cache.get('menuStatus') ? !!+Cache.get('menuStatus') : true,
  device: 'desktop'
})

const mutations = {
  MENU_TAGGER: (state) => {
    const status = !state.openMenu
    state.openMenu = status
    Cache.set('menuStatus', status ? 1 : 0)
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  }
}

const actions = {
  menuTagger({ commit }) {
    commit('MENU_TAGGER')
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
