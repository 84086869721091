<template>
  <el-breadcrumb separator="/">
    <transition-group name="breadcrumb" appear tag="div">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="'breadCrumb' + index">
        <span v-if="index==levelList.length-1" class="no-redirect">{{ item.meta.title }}</span>
        <a v-else>{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, watch } from 'vue'

const route = useRoute()

const levelList = ref([])

const isDashboard = (route) => {
  const name = route && route.name
  if (!name) {
    return false
  }
  return name.trim().toLocaleLowerCase() === 'Index'.toLocaleLowerCase()
}
const getBreadcrumb = () => {
  // only show routes with meta.title
  let matched = route.matched.filter(item => item.meta && item.meta.title)
  const first = matched[0]

  if (!isDashboard(first)) {
    matched = [{ path: '/dashboard', meta: { title: '首页' }}].concat(matched)
  }

  levelList.value = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
}

const router = useRouter()
watch(() => router.currentRoute.value.path, (toPath) => {
  if (route.path.startsWith('/redirect/')) {
    return
  }
  getBreadcrumb()
}, { immediate: true, deep: true })
</script>

<style>
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.5s ease;
}
.breadcrumb-enter-from,
.breadcrumb-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
